import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { DateTime } from "luxon"

import Layout from "components/Layout"
import Head from "components/Head"
import CardItem from "components/CardItem"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const CategoryTemplate = ({ data }) => (
  <Layout>
    <Head
      id="category"
      pageTitle={data.category.data.name}
      pageUrl={pjt.site.url + "/category/" + data.category.data.slug + "/"}
    />
    <section className="section is-items is-space-half">
      <div className="card">
        <div className="inner">
          <h1 className="heading">
            <span className="icon is-fit-lg is-dark-4">
              <FontAwesomeIcon
                icon={[
                  data.category.data.icon_style,
                  data.category.data.icon_name
                ]}
              />
            </span>
            <span className="text">{data.category.data.name}</span>
            <span className="text is-palt is-dark-5 is-sm">
              &nbsp;全
              {(() => {
                const items = data.category.data.items
                const result = items.filter(function(value) {
                  return value.data.published === true
                })
                return result.length
              })()}
              件
            </span>
          </h1>
        </div>
      </div>
      <ul className="grid is-stretch">
        {data.items.edges.map((edge, i) =>
          edge.node.data.published === true ? (
            <li
              className="col is-mobile-12 is-tablet-6 is-desktop-6 is-wide-4"
              key={i}
            >
              <CardItem
                to={`/items/${edge.node.data.slug}/`}
                src={edge.node.data.images[0].thumbnails.large.url}
                title={edge.node.data.title}
                imageCount={edge.node.data.images.length}
              />
            </li>
          ) : null
        )}
      </ul>
    </section>
  </Layout>
)

export default CategoryTemplate

export const query = graphql`
  query GetCategory($slug: String!) {
    category: airtable(data: { slug: { eq: $slug } }) {
      data {
        name
        slug
        icon_style
        icon_name
        items {
          data {
            published
          }
        }
      }
    }
    items: allAirtable(
      filter: {
        table: { eq: "items" }
        data: { categories: { elemMatch: { data: { slug: { eq: $slug } } } } }
      }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            images {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
